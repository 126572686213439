import React, { useEffect } from "react";

// Hooks
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// actions
import { fetchOrders } from "~/redux/actions/orders-actions";

// MUI Components
import { Box, Divider, Typography } from "@mui/material";

// Icons
import {
  ProfileCIcon,
  WalletCIcon,
  PlanCIcon,
  GeneralCIcon,
  TagsCIcon,
  ApiKeyCIcon,
  ApiLogsCIcon,
  WebhookCIcon,
  CustomFieldCIcon,
  OverviewCIcon,
  ContactSideBarCIcon,
} from "~/assets/images";

// styles
import "./styles.scss";

const ACCOUNT_SIDE_BAR_LIST = {
  title: "Account",
  list: [
    {
      id: 1,
      name: "Profile",
      icon: <ProfileCIcon />,
      link: "account/profile",
    },
    {
      id: 2,
      name: "Wallet",
      icon: <WalletCIcon />,
      link: "account/wallet",
    },
    {
      id: 3,
      name: "Users",
      icon: <ProfileCIcon />,
      link: "account/users",
    },
    {
      id: 4,
      name: "Plan",
      icon: <PlanCIcon />,
      link: "account/plan",
    },
  ].filter((item) => item !== false),
};

const SETTINGS_SIDE_BAR_LIST = {
  title: "Settings",
  list: [
    {
      id: 1,
      name: "General",
      icon: <GeneralCIcon />,
      link: "settings/general",
    },
    {
      id: 2,
      name: "Tags",
      icon: <TagsCIcon />,
      link: "settings/tags",
    },
    {
      id: 3,
      name: "Custom Fields",
      icon: <CustomFieldCIcon />,
      link: "settings/custom-fields",
    },
    {
      id: 4,
      name: "API Keys",
      icon: <ApiKeyCIcon />,
      link: "settings/api-keys",
    },
    {
      id: 5,
      name: "API Logs",
      icon: <ApiLogsCIcon />,
      link: "/",
    },
    {
      id: 6,
      name: "Webhooks",
      icon: <WebhookCIcon />,
      link: "settings/webhooks",
    },
  ].filter(
    (item) =>
      item.id === 4 ||
      item.id === 1 ||
      item.id === 2 ||
      item.id === 3 ||
      item.id === 6
  ),
};
const ORDER_DETAIL_SIDE_BAR_LIST = {
  title: "Order Details",
  list: [
    {
      id: 1,
      name: "Overview",
      icon: <OverviewCIcon />,
      link: "orders/detail/overview",
    },
    {
      id: 2,
      name: "Contacts",
      icon: <ContactSideBarCIcon />,
      link: "orders/detail/contacts",
    },
  ].filter((item) => item !== false),
};

const SideBar = ({ isSidebarOpen, name, isImpersonatedMode }) => {
  const sideBarTransition = isSidebarOpen ? "sideBar opened" : "sideBar";
  let currentSideBar;
  switch (name) {
    case "account":
      currentSideBar = ACCOUNT_SIDE_BAR_LIST;
      break;
    case "order":
      currentSideBar = ORDER_DETAIL_SIDE_BAR_LIST;
      break;
    default:
      currentSideBar = SETTINGS_SIDE_BAR_LIST;
  }

  const { id } = useParams();
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.ordersReducers.orders);
  const orderName = orders.find((order) => order.id === id)?.name;

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <>
      <Box
        className={
          isImpersonatedMode
            ? `sideBar ${sideBarTransition} impersonate-sidebar`
            : `sideBar ${sideBarTransition}`
        }
      >
        {currentSideBar === ORDER_DETAIL_SIDE_BAR_LIST ? null : (
          <Typography>{currentSideBar.title}</Typography>
        )}
        {currentSideBar.title !== "Settings" && id && (
          <Box className="order_id_name">
            <Box className="order_details_wrapper">
              <Typography className="entity_title">{currentSideBar.title}</Typography>
              <Box className="order_details_wrapper_content">
                <Typography className="entity_value">{id}</Typography>
                {orderName && orderName !== " -" && (
                  <>
                    <Divider className="divider" />
                    <Typography className="entity_value">
                      Name: {orderName}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Box className="sideBarLinks">
          {currentSideBar.list.map((item) => {
            return (
              <NavLink
                key={item.id}
                className={({ isActive }) => (isActive ? "activeItem" : "")}
                to={id ? `${item.link}/${id}` : item.link}
              >
                <Box className="linkWrapper">
                  <Box className="linkIcon">{item.icon}</Box>
                  <Box>
                    <Typography className="linkText">{item.name}</Typography>
                  </Box>
                </Box>
              </NavLink>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SideBar;
